import apiClient from "./http-common";

class PaymentService {
  create(order_type, email) {
    return apiClient.post("/payment-processing/create", {
      order_type: order_type,
      email: email
    });
  }
}

export default new PaymentService();
